@media print
 {

header,
.slick-arrow,
.navi-prev,
.navi-next {
  display: none;
}

body {
   margin: 0;
   padding: 0;
   background-color: #FFF;
   font-family: "Times New Roman", Times, serif;
   }

h1, h2, h3, h4, h5, h6 {
   font-family: Arial, Helvetica, sans-serif;
   }

h1 {font-size: 16pt;}
h2 {font-size: 14pt;}
h3, h4, h5, h6 {font-size: 12pt;}

p {line-height: 1.4; font-size: 12pt;}

a:link, a:visited {
   text-decoration: underline;
   font-weight: bold;
   color: #000;
   }


img {border: 1px solid #000;}

#menu, #vyhledavaci-formular {
   display: none;
   }

 ul * {
   display: block;

 }

   }
