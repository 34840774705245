@import '_mixins/pux/breakpoints';
@import '_variables';
@import '_mixins/pux/css-rules';
@import '_mixins/_base';
@import '../fonts/style.scss';


.product-item {
  display: inline-block;
  padding: 20px;
  border-top: 1px;
  border-bottom: 1px;
  border-right: 1px;
  border-left: 0px;
  border-color: $product-border-color;
  border-style: solid;
  text-align: center;
  color: $dark-text;

  @include media(xxs, xs) {
    border-left: 1px solid $product-border-color;

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }

  @include media(sm, md) {
    &:nth-of-type(1n+3) {
      border-top: 0px;
    }

    &:nth-of-type(2n+1) {
      border-left: 1px solid $product-border-color;
    }
  }

  @include media(lg) {
    &:nth-of-type(1n+4) {
      border-top: 0px;
    }

    &:nth-of-type(3n+1) {
      border-left: 1px solid $product-border-color;
    }


  }

  @include breakpointhelper(545px, 575px) {
    border-left: 1px solid $product-border-color;
    border-top: 1px solid $product-border-color !important;

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }

  .product-new {
    position: absolute;
    top: 15px;
    left: 15px;
    display: inline-block;
    padding: 2px 5px;
    @include font-size(13);
    color: white;
    background-color: $theme-corporate;
    text-transform: uppercase;
    letter-spacing: 2px;
    z-index: 9;
  }

  .img-holder {
    position: relative;
    width:90%;
    margin: 5%;

    img {
      @include vertical-pos(50%);
      @include transition(0.1s);
      vertical-align: middle;
      max-width: 100%;
      max-height: 100%;
    }

    @include media(xxs, xs) {
      height: 100px;
    }

    @include media(sm, md) {
      height: 150px;
    }
    @include media(lg) {
      height: 310px;
    }
  }

  .product-details {
    display: inline-block;
    @include font-size(12);
    width:94%;
    text-align: left;

    ul {
      list-style: none;
      padding:0;

      li {
        margin-bottom: 9px;
        padding: 0px;
        text-indent: -22px;
        margin-left: 22px;

        &:before {
          @include icomoon();
          content: $icon-triangle-right;
          @include font-size(9);
          vertical-align: 2px;
          margin-right: 17px;
        }
      }
    }
  }

  &:hover,
  &:focus {
    img {
      opacity: .75;
    }
    h3 {
      text-decoration: none;
      color: $base-light;
    }
    li {
      color: $dark-text;
    }
  }
}

.product-heading-width {
  @include media(md) {
    max-width: calc(100% - 250px);
  }
}

.product-back-home {
  color: $theme-corporate;
  @include font-size(16);
  font-weight: 400;

  &:hover,
  &:focus {
    color: $base-light;
    text-decoration: none;
  }

  @include media(xxs, sm) {
    margin-bottom: 38px;
  }

  @include media(md) {
    position: absolute;
    top: 10px;
    right: 15px;
  }

}


.product-gallery {

  .product-img-big {
    position: relative;
    width:100%;
    height: 30vh;
    margin-top: 30px;
    text-align: center;

    @include media(lg) {
      height: 530px;
      margin-top: 0px;
    }

    img {
      @include vertical-pos(50%);
      display: inline;
      max-width: calc(100% - 40px);
      max-height: 100%;
    }

    .product-video {
      display: inline-block;
      @include vertical-pos(50%);
      width: calc(100% - 100px);
      height: 100%;

      @include media(xxs, xxs) {
        height: 80%;
      }

      @include media(sm, md) {
        width: auto;
        height: 100%;
      }      

      @include media(lg) {
        width: 80%;
        height: 70%;
      }

      iframe {
        display: inline-block;
        width: 100%;
        height: 100%;
      }

      }

    .navi-prev,
    .navi-next {
      @include translate(0, -50%);
      @include icomoon();
      @include transition(.1s);
      @include unselectable();
      position: absolute;
      top: 50%;
      @include font-size(13);
      text-align: center;
      cursor: pointer;
      background-color: $theme-corporate;
      color: white;

      @media (hover:hover) {
        &:hover,
        &:focus {
          background-color: $base-light;
        }
      }

      &:active {
        background-color: $base-light;
      }

    }

    .navi-prev {
      left: 0;
      padding: 13px 17px 13px 15px;

      &:before {
        content: $icon-SLIDER_arrow-prev;
      }
    }

    .navi-next {
      right: 0;
      padding: 13px 14px 13px 17px;

      &:before{
        content: $icon-SLIDER_arrow-next;
      }
    }
  }

  .product-gallery-list {
    overflow: hidden;

    .product-gallery-item {
      display: block;
      position: relative;
      float: left;
      border: 1px solid $gallery-border;
      text-align: center;
      min-height: 173px;


      @include media(xxs, xxs) {
        @include calc(width, "100% / 2 - 12px");
        margin: 5px;
      }

      @include media(xs, sm) {
        @include calc(width, "100% / 3 - 12px");
        margin: 5px;
      }

      @include media(md, md) {
        @include calc(width, "100% / 4 - 12px");
        margin: 5px;
      }

      @include media(lg) {
        @include calc(width, "100% / 3 - 8px");
        margin-bottom: 10px;
        margin-right: 10px;

        &:nth-of-type(3n+3) {
          @include calc(width, "100% / 3 - 7px");
          margin-right: 0px;
        }
      }

      img {
        @include translate(-50%, -50%);
        position: absolute;
        top: 50%;
        left: 50%;
        display: inline;
        max-width: 100%;
        max-height: 100%;
        padding: 10px;
      }

      &:hover,
      &:focus {
        border-color: $theme-corporate;
      }
    }

    .video-link {
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 50px;
        height: 40px;
        z-index: 9;
        border-radius: 5px;
        @include translate(-50%, -50%);
        background-color: rgba(black, .75);
        @include transition(.1s);
      }

      &:hover {
        &:before {
          background-color: rgba($theme-corporate, .95);
        }
      }

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: 2px;
        @include translate(-50%, -50%);
        z-index: 10;
        @include triangle-right(12px, 12px, 20px, #ffffff);
        @include transition(.1s);
      }
    }

    @include media(xxs, md) {
      padding-top: 30px;
    }

    @include media(lg) {
      padding-left: 37px;
    }
  }
}

.product-summary {
  @include font-size(18);
  color: $dark-text;
  letter-spacing: 0px;
  line-height: 1.5;
  margin-top: 22px;
  margin-bottom: 65px;

  .product-summary-text {

    @include media(md) {
      padding-right: 65px;
    }
  }

  ul {
    list-style: none;


    li {
      padding: 0px;
      text-indent: -22px;
      margin-left: 22px;
      @include font-size(15);
      font-weight: 300;
      color: $dark-text;
      line-height: 1.2;

      &:before {
        @include icomoon();
        content: $icon-triangle-right;
        @include font-size(9);
        vertical-align: 2px;
        margin-right: 17px;
      }

      &:not(:last-of-type) {
        margin-bottom: 12px;
      }
    }

    @include media(xxs, sm) {
      padding: 8px 0 0 5px;
      margin-top: 30px;
    }

    @include media(md) {
      padding: 8px 30px 0 0px;
      margin-top: 75px;
    }
  }
}

.product-use {

    ul {
    list-style: none;
    border-bottom: 1px solid $product-border-color;

    @include media(xxs, md) {
      padding: 0 0 10px 10px;
    }

    @include media(md, md) {
      @include column-count(2);
    }

    @include media(lg) {
      @include column-count(3);
      @include column-gap(25px);
      padding: 5px 0 57px ;
    }

    li {
      @include font-size(15);
      font-weight: 700;
      color: $dark-text;
      margin-bottom: 22px;
      text-indent: -30px;
      margin-left: 30px;

      &:before {
        @include icomoon();
        content: $icon-shield;
        color: $theme-corporate;
        @include font-size(19);
        vertical-align: -5px;
        margin-right: 15px;
      }
    }
  }
}

.product-info {
  margin-bottom: 12px;

  .product-info-box {
    color: $dark-text;
    @include font-size(15);
    font-weight: 300;
    color: $theme-primary;
    letter-spacing: 0px;
    line-height: 1.6;
    margin-bottom: 50px;
    padding-right: 30px;
  }
}


.product-download {
  width: 100%;
  padding: 30px;
  margin-bottom: 50px;
  border: 1px solid $product-border-color;

  @include media(md) {
    display: flex;
  }

  .product-download-item {
    position: relative;
    padding-left: 44px;

    @include media(xxs, sm) {
      display: inline-block;
      width: 100%;

      &:not(:last-of-type) {
        margin-bottom: 30px;
      }
    }

    @include media(md, md) {
      width: 300px;
    }

    @include media(lg) {
      width: 336px;
    }

    .product-download-details {
      float: left;

      &:before {
        content: "";
        position: absolute;
        top: 0%;
        left: -2px;
        display: inline-block;
        width: 31px;
        height: 40px;
        background-image: url("../img/icon-pdf.png");
      }

      a {
        display: block;
        @include font-size(16);
        height: auto;
        margin-top: -5px;
        color: $theme-corporate;

        &:hover,
        &:focus {
          color: $base-light;
          text-decoration: none;
        }
      }

      div {
        @include font-size(11);
        color: $download-details;
        text-transform: uppercase;
        margin-top: 8px;
      }

      &:after {
        content: "";
        display: table;
        clear: both;
        margin-bottom: -3px;
      }
    }
  }
}


@keyframes loading-animate {
  100% {
    transform: rotate(360deg);
  }
}

.loading-animation {
  position: relative;

  &:after {
    @include icomoon();
    @include font-size(35);
    position: absolute;
    content: $icon-spinner3;
    color: gray;
    top: 47%;
    left: 48%;
    animation: loading-animate 1s linear infinite;
    z-index: 2;

    @include media(xxs, md) {
      top: 43%;
      left: 48%;
    }
  }
}

.loading-background {
  &:before {
    position: absolute;
    content: "";
    padding: 50px;
    top: 45%;
    left: 45%;
    z-index: 1;
    background-color: #FFFFFF;

    @include media(xxs, sm) {
      top: 20%;
      left: 45%;
    }

    @include media(md, md) {
      top: 40%;
    }
  }
}
