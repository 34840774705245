@import '_mixins/pux/breakpoints';
@import '_variables';
@import '_mixins/pux/css-rules';
@import '_mixins/_base';
@import '../fonts/style.scss';


$radio-background: #F9F9F9;
$radio-border: $theme-corporate;
$radio-after-background: $theme-corporate;
$radio-active-border: $theme-corporate;
$checkbox-background: $radio-background;
$checkbox-border: $radio-border;
$checkbox-after-background: $radio-after-background;
$checkbox-active-border: $radio-active-border;
 
.radio{
    input[type="radio"]{
        display:none;
        & +label{
            position:relative;
            padding:0 0 10px 25px;
            display: inline-block;
            margin:0;
            &:before{
                content:"";
                @include border-radius(50%);
                width:16px;
                height:16px;
                background:$radio-background;
                display:block;
                position:absolute;
                left:0;
                top:3px;
                border:1px solid $radio-border;
                @include transition;
            }
            &:after{
                content:"";
                display:block;
                left:5px;
                top:8px;
                width:6px;
                height:6px;
                background:$radio-after-background;
                @include border-radius(50%);
                position:absolute;
                @include opacity(0);
                @include transition;
            }
        }
        &:checked{
            & +label{
                &:before{
                    border:1px solid $radio-active-border;
                }
                &:after{
                    @include opacity(1);
                }
            }
        }
    }
}

.checkbox{
    input[type="checkbox"]{
        display:none;
        & +label{
            position:relative;
            padding:0 0 10px 25px;
            margin:0;
            display: inline-block;
            &:before{
                content:"";
                width:16px;
                height:16px;
                background: $checkbox-background;
                display:block;
                position:absolute;
                left:0;
                top:3px;
                border:1px solid $checkbox-border;
                @include transition;
            }
            &:after{
                content:"";
                display:block;
                left:5px;
                top:8px;
                width:6px;
                height:6px;
                background:$checkbox-after-background;
                position:absolute;
                @include opacity(0);
                @include transition;
            }
        }
        &:checked{
            & +label{
                &:before{
                    border:1px solid $checkbox-active-border;
                }
                &:after{
                    @include opacity(1);
                }
            }
        }
    }
}
