@import '_mixins/pux/breakpoints';
@import '_mixins/pux/css-rules';
@import '_variables';

body, html {
  color: $base-color;
  font-family: $base-font;
  height: 100%;
  font-size: $font-mobile;
  letter-spacing: 0px;
  margin:0;
  padding:0;

  @include media(md) {
    font-size: $font-desktop;
  };
}

img {
  max-width: 100%;
}

h1, .h1 {
  font-size: 27px;
  font-weight: 300;
  margin: 50px 0 30px;

  @include media (md) {
    font-size: 54px;
    margin: 100px 0 60px;
  };
}

h2, .h2 {
  font-size: 28px;
  font-weight: 700;
  margin: 55px 0 55px;
  color: $theme-primary;
  letter-spacing: 0px;
}

h3, .h3 {
  font-size: 16px;
  font-weight: bold;

  @include media(md) {
    font-size: 28px;
  }
}

h4, .h4 {
  font-size: 18px;
  font-weight: 700;
  color: $theme-primary;
  margin: 0 0 19px;
  letter-spacing: 0px;
}

.h2, .h3, .h4 {
  display: block;
}

p {
  margin-bottom: 15px;

  @include media(md) {
    margin-bottom: 30px;
  };
}

* {
 box-sizing: border-box;
}

/*
body {
  @include media(xs) {
    color: red;
  }
  @include media(sm) {
    color: blue;
  }
  @include media(md) {
    color: green;
  }
  @include media(lg) {
    color: purple;
  }
  @include media(xs, sm) {
    color: orange;
  }
  @include media(xs, md) {
    color: cyan;
  }
  @include media(sm, md) {
    color: gray;
  }
}*/
