@import "variables";

@font-face {
  font-family: 'icomoon';
  src:  url('#{$icomoon-font-path}/icomoon.eot?3a13bx');
  src:  url('#{$icomoon-font-path}/icomoon.eot?3a13bx#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/icomoon.ttf?3a13bx') format('truetype'),
    url('#{$icomoon-font-path}/icomoon.woff?3a13bx') format('woff'),
    url('#{$icomoon-font-path}/icomoon.svg?3a13bx#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */ 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
