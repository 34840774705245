@import '_mixins/pux/svg-sprites';
@import '_mixins/pux/img-sprites';
@import '_mixins/pux/breakpoints';
@import '_variables';
@import "helpers";
@import "commons";
@import "../plugins/slick/slick.scss";
@import "../plugins/slick/slick-theme.scss";
@import "../plugins/magnific/main.scss";

//@import "helpers";

//@import "forms";
//@import "others";
//@import "boxes";
//@import "vitamines";
//@import "kontakt";
//@import "blog";
//@import "quiz";
//@import "basket";
//@import "color-themes";

@import "casio-form";
@import "casio-typography";
@import "casio-layout";
@import "casio-content";
@import "casio-product";
@import "casio-print";
