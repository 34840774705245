@import '_mixins/pux/breakpoints';
@import '_variables';
@import '_mixins/pux/css-rules';
@import '_mixins/_base';
@import '../fonts/style.scss';

.slider-h1{
  @include font-size(36);
  font-weight: 700;
  margin: 0px 0 30px;
  line-height: 1.1;

  @include media(lg, lg) {
    @include font-size(28);
    margin: 0px 0 20px;
  }
}


.product-h3 {
  @include font-size(22);
  font-weight: 700;
  letter-spacing: 0px;
  color: $theme-corporate;
  text-transform: none;
  margin: 38px 0 20px 0px;
}

.product-details-list {
  @include font-size(15);
  font-weight: 300;
  color: $dark-text;
  letter-spacing: 0px;
}


h1 {
  @include font-size(36);
  font-weight: 700;
  margin: 39px 0 38px;
  color: $theme-primary;
  letter-spacing: 0px;
  letter-spacing: 0.035rem;

  @include media(xxs, sm) {
    @include font-size(30);
    margin: 39px 0 20px;
  }
}

h2 {
  font-size: 28px;
  font-weight: 700;
  margin: 50px 0 55px;
  color: $theme-primary;
  letter-spacing: 0px;

  @include media(xxs, sm) {
    @include font-size(25);
    margin: 30px 0 30px;
  }
}

h3 {
  font-size: 28px;
  font-weight: 400;
  margin: 20px 0 28px;
  color: $theme-primary;
  letter-spacing: 0px;
  letter-spacing: 0.025rem;

  @include media(xxs, sm) {
    @include font-size(25);
    margin: 20px 0 20px;
  }
}

.contact-h3 {
  font-size: 28px;
  font-weight: 400;
  margin: 53px 0 32px;
  color: $theme-primary;
  letter-spacing: 0px;
  letter-spacing: 0.012rem;
  line-height: 30px;

  @include media(xxs, sm) {
    @include font-size(25);
    margin: 50px 0 20px;
  }
}


b,
strong {
  font-weight: bolder;
}

dfn {
  font-style: italic;
}

mark {

}

small {
  font-size:80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline
}


.btn {
  @include transition(0.1s);
  @include border-radius(0px);
  display: inline-block;
  margin: 5px 0 70px;
  padding: 11px 51px;
  border: 1px solid  $theme-corporate;
  background-color: $theme-corporate;
  color: $base-color;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: white;
    color: $theme-corporate;
  }

  @include media(xxs, sm) {
    @include translate(-50%, 0);
    position: relative;
    left: 50%;
    margin-top: 0px;
    margin-bottom: 40px;
  }

  @include media(md) {
    float: right;
  }
}
