
//main

$base-font: 'Roboto', sans-serif;
$font-size: 16px;
$font-desktop: 16px;
$font-mobile: 14px;
$base-color: #FFFFFF;
$base-light: #90defe;

//colors
$theme-corporate: #005ba9;
$theme-primary: #002b50;
$theme-secondary: #eb5055;
$product-border-color: #e6e6e6;
$dark-text: #131313;
$gallery-border: #e6e6e6;
$download-details: #4b4b4b;
$download-table-border: #e6e6e6;

//dimensions

$page-width: 1170px;
$page-mobile-padding: 10px;
$menu-height: 70px;
$menu-height-small: 40px;
$menu-logo-width: 137px;
$menu-logo-height: 26px;
$menu-mobile-button-width: 30px;

//arrays
$download-table-names: (2: "Product", 3: "Filetype", 4: "Size");
