@import '_mixins/pux/breakpoints';
@import '_variables';
@import '_mixins/pux/css-rules';
@import '_mixins/_base';
@import "../fonts/variables";
@import "../fonts/style.scss";


.sliders {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 100%;
  margin-top: - $menu-height;
  padding-top: $menu-height;
  background-color: $theme-corporate;
  overflow: hidden;

  @include media(lg) {
    height: 420px + $menu-height;
  }

  .slider-item {
    position: relative;
    text-align: center;

    .slider-image {
      width: 100%;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;

      @include media(xxs, sm) {
        height: 120px;
      }

      @include media(md, md) {
        height: 380px;
      }

      @include media(lg) {
        height: 420px;
      }
    }

    .slider-content {
      .slider-h1{
        display: block;
        @include font-size(36);
        font-weight: 700;
        margin: 0px 0 30px;
        line-height: 1.1;

        @include media(lg, lg) {
          @include font-size(28);
          margin: 0px 0 20px;
        }
      }

      .slider-perex {
        @include font-size(18);
        letter-spacing: 0px;
        margin-bottom: 35px;

        @include media(xxs, md) {
          display: inline-block;
          width: 70%;
          margin-bottom: 20px;
        }

        @include media(lg, lg) {
          @include font-size(16);
        }
      }

      a {
        @include transition(.1s);
        display: inline-block;
        padding: 9px 26px;
        background-color: #FFFFFF;
        text-decoration: none;
        color: $theme-corporate;
        letter-spacing: 0px;
        border: 1px solid transparent;

        &:hover,
        &:focus {
          background-color: $base-light;
        }

        @include media (xxs, md) {
          margin: 0 20%;

          &:hover,
          &:focus {
            background-color: transparent;
            color: white;
            border: 1px solid white;
          }
        }
        @include media (lg) {
          &:hover,
          &:focus {
            background-color: $base-light;
          }
        }
      }

      @include media(xxs, sm) {
        width: 100%;
        color: $base-color;
        background-color: $theme-corporate;
        text-align: center;
        padding: 20px 15px;
      }

      @include media(md, md) {
        width: 100%;
        position: absolute;
        display: inline-block;
        top: 50%;
        left: 50%;
        @include translate(-50%, -50%);
        color: $base-color;
        text-align: center;
      }

      @include media(lg) {
        @include calc('width', '('$page-width' / 12 - 10px) * 6 ');
        @include calc('margin-left', '('$page-width' / 12 + 20px) * -1');
        position: absolute;
        display: inline-block;
        top: 50%;
        left: 50%;
        @include translate(-50%, -50%);
        color: $base-color;
        text-align: left;
      }
    }
  }

  nav {
    position: absolute;
    display: inline-block;
    width: 100%;
    top: 50%;
    left: 50%;
    height: 0;
    background-color: red;
    @include translate(-50%, -50%);
    color: white;

    .navi-left,
    .navi-right {
      @include icomoon();
      @include transition(.1s);
      @include font-size(13);
      text-align: center;
      cursor: pointer;

      @include media(lg) {
        &:hover,
        &focus {
          background-color: $base-light;
        }
      }

      @include media(lg) {
        background-color: $theme-corporate;
      }
    }

    .navi-left {
      float: left;
      padding: 13px 17px 13px 15px;

      &:before {
        content: $icon-SLIDER_arrow-prev;
      }

      @include media(xxs, md) {
        @include font-size(25);
        padding: 20px 20px 20px 20px;
        margin-left: -13px;
        color: rgba(white, 0.6);
      }
    }

    .navi-right {
      float: right;
      padding: 13px 14px 13px 17px;

      &:before{
        content: $icon-SLIDER_arrow-next;
      }

      @include media(xxs, md) {
        @include font-size(25);
        padding: 20px 20px 20px 20px;
        margin-right: -13px;
        color: rgba(white, 0.6);
      }
    }
  }
}

.solutions-info {
  margin-bottom: 12px;

  .solutions-info-box {
    padding: 0 25px 20px 20px;
    margin: 0;
    color: $dark-text;

    @include media('xxs', 'sm') {
      padding-left: 0px;
    }

    @include media('md') {
      &:nth-of-type(2n+2) {
        padding-left: 15px;
      }
    }

    @include media('lg') {
      &:nth-of-type(3n+1) {
        padding-left: 0px;
      }
      &:nth-of-type(3n+3) {
        padding-left: 40px;
      }
    }
  }

  div {
    @include font-size(15);
    font-weight: 300;
    color: $theme-primary;
    letter-spacing: 0px;
    line-height: 1.6;
    margin-bottom: 30px;
  }
}

.download-table {

  table {
    @include font-size(15);
    width: 100%;
    color: $dark-text;
    margin-top: 52px;
    margin-bottom: 16px;

    @include media(xxs, sm) {
      margin-top: 0px;
    }

    thead {
      @include media(xxs, sm) {
        display: none;
      }
    }

    th {
      font-weight: 300;
      padding-bottom: 10px;
      border-bottom: 1px solid $download-table-border;

      &:nth-of-type(1){
        width: 49%;
        padding-left: 30px;
      }
      &:nth-of-type(2){
        width: 15.5%;
      }
      &:nth-of-type(3){
        width: 12.5%;
      }
      &:nth-of-type(4){
        width: 11.5%;
      }
    }


    tr {
      @include media(xxs, sm) {
        display: inline-block;
        width:100%;
        padding: 30px 0px;

        &:not(:first-of-type) {
          border-top: 1px solid $download-table-border;
        }
        &:last-of-type {
          border-bottom: 1px solid $download-table-border;
        }
      }
      @include media(md) {
          border-bottom: 1px solid $download-table-border;
      }

      td {

        @include media(xxs, sm) {
          display: block;
          position: relative;
          padding-left: 85px;

          &:not(first-of-type) {
            margin-top: 10px;
            &:before {
              position: absolute;
              top: 0;
              left: 15px;
              display: inline-block;
            }
          }

          &:nth-of-type(1) {
            @include font-size(16);
            font-weight: 700;
            margin-bottom: 20px;
          }

          @each $order, $text in $download-table-names {
            &:nth-of-type(#{$order}) {
                &:before {
                  content: "#{$text}:";
                }
              }
          }

          &:last-of-type {
            margin-top: 20px;

            a {
              background-color: $theme-corporate;
              color: $base-color;
              padding: 10px;
              margin-top: 40px;
              margin-bottom: 10px;
            }
          }
        }

        @include media(md) {
          padding-top: 24px;
          padding-bottom: 24px;
        }

        &:first-of-type {
          position: relative;
          padding-left: 85px;

          a:before {
            @include translate(0, -50%);
            content: "";
            position: absolute;
            top: 50%;
            left: 29px;
            display: inline-block;
            width: 21px;
            height: 30px;
            background-image: url("../img/icon-pdf.png");
            background-size: 100% 100%;

            @include media(md) {
              width: 31px;
              height: 40px;
            }

          }
        }

        &:last-of-type {
          @include media(md) {
            text-align: center;
          }
        }

        a {
          &:hover,
          &:focus {
            color: $base-light;
            text-decoration: none;
          }
        }
      }
    }
  }

  .download-pagination {
    @include font-size(18);
    width: 100%;
    text-align: right;
    margin-bottom: 50px;
    padding-right: 20px;

    a {
      display: inline-block;
      padding: 10px;
      color: $dark-text;

      &:hover,
      &:focus {
        color: $theme-primary;
        font-weight: 700;
        text-decoration: none;
      }
    }

    .download-next-page {
      &:after {
        @include icomoon();
        content: $icon-PAGE-NUMBERING_arrrow;
        @include font-size(10);
        vertical-align: middle;
      }
    }
  }
}


.contact-adress {
  @include font-size(18);
  color: $dark-text;

  .contact-adress-company {
    margin-bottom: 15px;
    font-weight: 700;

    @include media(xxs, md) {
      margin-top: -10px;
      margin-bottom: 0px;
    }

  }
}

.contact-contact {
  @include font-size(18);
  color: $dark-text;

  @include media(xxs, md) {
    margin-top: 40px;
  }

  table {

    td {
      vertical-align: top;
      height: 40px;

      @include media(xxs, md) {
        height: 25px;
      }

      &:first-of-type {
        padding-right: 20px;
      }

      a {
        color: $theme-corporate;

        &:hover,
        &:focus {
          color: $base-light;
          text-decoration: none;
        }
      }
    }
  }
}

.contact-form {

  .contact-form-pair {
    vertical-align: top;
    width: 100%;

    .contact-form-item {
      margin-bottom: 25px;

      @include media(md) {
        float: left;
        width:50%;

        &:first-of-type {
          padding-right: 20px;
        }
        &:last-of-type {
          padding-left: 20px;
        }
      }

      @include media(lg) {
        &:first-of-type {
          padding-right: 45px;
        }
        &:last-of-type {
          padding-left: 45px;
        }
      }

      label {
        @include font-size(15);
        color: $dark-text;
        margin: 0px 0 9px;
        display: block;
     }

      input,
      textarea {
        background-color: #F9F9F9;
        border: 1px solid #E5E5E5;
        width: 100%;
        padding: 11px 9px;
        color: $theme-corporate;
        @include border-radius(0px);

        &:focus {
          border: 1px solid  $theme-corporate;
          outline: 0;
        }
     }

     textarea {
       height: 90px;

       &:-webkit-resizer {
       display: none;
      }
     }

     .recaptcha-cont {
       width: 100%;
       text-align: right;
       margin-top: 47px;

       @include media(xxs, sm) {
         margin-top: 20px;
         text-align: center;
       }

       & > div {
        display: inline-block;

        @include media(xxs, xxs) {
         @include scale(0.8, 0.8);
         margin-left: -3%;
        }
       }
      }
    }

    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }
}


.EditingFormErrorLabel {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-top: 9px;
  color: red;
}

.ErrorLabel {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 32px;
  color: red;
}

.page-404-cont {
  width: 100%;
  text-align: center;

  @include media(xxs, sm) {
    margin: 50px 0;
  }

  @include media(md) {
    margin: 180px 0;
    margin-left: 8px;
  }

 img {
   @include media(xxs, sm) {
    max-width: 80%;
   }
 }
}
