@import '_mixins/pux/breakpoints';
@import '_variables';
@import '_mixins/pux/css-rules';
@import '_mixins/_base';


.cms-bootstrap [class^="icon-"]:before,
.cms-bootstrap [class*=" icon-"]:before {
  font-family: 'Core-icons' !important;
}

.DesignMode header,
.EditMode header {
  position:static;
}

body {
  font-family: $base-font;
  font-weight: 400;
  color: $dark-text;

  .none {
    display: none;
  }
}

header {
   position: fixed;
   width: 100%;
   height: $menu-height;
   background-color: $theme-corporate;
   text-align: center;
   z-index: 999;
   @include transition();

    .pux-container {
       position: relative;
       display: inline-block;
       width: 100%;
       height: 100%;
       text-align: center;
    }
    .logo {
        float:left;
        width: $menu-logo-width;
        height: 100%;
        text-align: left;
        @include transition();
        position: relative;

        span,
        h1 {
          display: none;
        }

        img {
          vertical-align: top;
          @include vertical-pos(50%);
          @include transition();
          position: absolute;

          @include media('lg') {
            width: $menu-logo-width;
            height: $menu-logo-height;
          }

          &.scrolled {
            @include media('lg') {
              @include resize-by-ratio();
            }
          }
        }

        &.scrolled {
          @include media('lg') {
            height: $menu-height-small;
          }
        }
    }
    .header-menu {
        @include media('lg') {
          display: block;
          float: right;
          text-align: right;

          @include calc('width', '100% - 200px');
          @include vertical-pos(50%);
          @include font-size(15);
        }

        &-mobile-button {
            display: inline-block;
            height: $menu-height;
            position: relative;
            @include transition(.15s);
            cursor: pointer;
            text-align: center;
            padding: 0px 15px;

          span,
          span:before,
          span:after {
            display: block;
            content: '';
            height: 3px;
            width: 35px;
            background: white;
            @include transition(.125s);
          }

          span:before{
            position: absolute;
            top: -10px;
            @include transform-origin (left center);
          }

          span {
            position: relative;
            @include calc(margin-top, $menu-height/ 2);
            @include transform-origin (left center);
          }

          span:after {
            position: absolute;
            top: 10px;
            @include transform-origin (left center);
          }

          @include media(xxs, md) {
            float: right;
          }

          @include media(lg) {
            display: none;
          }

            &:focus {
              background-color: $base-light;
            }

            &.open {
              span:before {
                @include  rotate(45deg);
                top: -12px;
                left: 0px;
              }

              span{
                 background: transparent;
              }

              span:after {
                @include  rotate(-45deg);
                top: 12px;
                left: 0px;
              }
            }

          }
        .main-menu {
          @include unselectable();

          @include media('xxs', 'md') {
            float: right;
            display: none;
            background-color: rgba(black, .9);
            @include calc('width', '100% + 60px');
            margin-right: -30px;
            text-align: left;
          }
          @include media('lg') {
            display: block !important;
          }

           ul {
              margin:0px;
              list-style-type: none;

              @include media('xxs', 'md') {
                margin-top: 10px;
                margin-bottom: 10px;
              }

                li {
                  text-transform: uppercase;
                  color: $theme-primary;
                  @include font-size (15);
                  letter-spacing: 1px;
                  height: 25px;
                  @include transition();

                  @include media('xxs', 'md') {
                    padding: 20px 0px 40px 20px;
                  }
                  @include media('lg') {
                    display: inline-block;
                    padding-top:3px;

                    &:not(:first-child) {
                      margin-left:70px;
                    }
                  }
                  a {
                    text-decoration: none;
                    color: $base-color;
                    position: relative;

                    &:hover,
                    &:focus {
                      color: $base-light;
                    }
                  }
                }

                .Highlighted {
                  a {
                    color: $base-light;
                  }
                }
            }
        }
    }
    &.scrolled {
      @include media('lg') {
        height: $menu-height-small;
      }
    }
}

main {
  position: relative;
  padding-top: $menu-height;
  @include calc("min-height", "100vh - "$menu-height);
}

footer {
  display: inline-block;
  position: relative;
  width: 100%;
  background-color: $theme-corporate;
  @include font-size(13);
  color: $base-color;
  letter-spacing: 0px;
  vertical-align: middle;


  .pux-container {
    position: relative;

    .all-rights {
      @include media('xxs','sm') {
        .all-rights-space {
          display: none;
          padding:0;
        }
        .pux-link {
          display: block;
        }
      }

      @include media('md') {
        @include vertical-pos(50%);
          float: left;

          .all-rights-space {
            display: inline-block;
            padding: 0 15px;
          }
      }
    }

    .faster {
      @include media('md') {
        @include vertical-pos(50%);
        float: right;
      }
    }

    @include media('xxs','sm') {
      text-align: center;
      margin: 20px 0;
      line-height: 2.5;
    }


    @include media('md') {
      height: $menu-height;
    }
  }

  a {
    color: $base-light;

    &:hover,
    &:focus {
     color: $base-light;
     text-decoration: none;
    }
  }
}

.pux-container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    @include transition;

    @include media(xl) {
        max-width: 1200px;
    }
}

.pux-container::after {
    content: "";
    display: table;
    clear: both;
}
