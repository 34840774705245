@import '_variables';

@mixin font($type, $size, $weight:normal) {
  font-family: $type;
  font-size: $size;
  font-weight: $weight;
}

@function calculateRem($size) {

  $remSize: $size / 16;

  @return #{$remSize}rem;

}

@mixin font-size($size) {

  font-size: #{$size}px;

  font-size: calculateRem($size);
  
}


@mixin resize-by-ratio($ratio: 0.75) {
  @include calc('width', $menu-logo-width ' * ' $ratio);
  @include calc('height', $menu-logo-height ' * ' $ratio);
}


@mixin icomoon() {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin unselectable() {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
